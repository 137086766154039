<template>
    <a-spin :spinning="spinning">
        <div class="container">
            <div style = "width: 90%; margin: 5rem auto;">
                <a-row>
                    <a-col :span = "8" :offset="8">
                        <a-steps :current="currentStep" labelPlacement="vertical">
                            <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                        </a-steps>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8" :offset="8">
                        <div style = "margin: 3rem auto 5rem;">
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" v-if="currentStep == 0">
                                <a-form-item label="手机号">
                                    {{userInfo.mobile}}
                                </a-form-item>
                                <a-form-item label="验证码" required>
                                    <a-input v-model="verify_code" placeholder="请输入验证码" size="large">
                                        <div slot="suffix" class="getVerifyCode" :class="countDown<60 ? 'disableColor' : ''" @click="getVerifyCode(11)">
                                            {{getVerifyCodeText}}
                                        </div>
                                    </a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-row>
                                        <a-col :span="16" offset="7">
                                            <a-button :disabled="verify_code.length != 6" @click="verifyPhone">下一步</a-button>
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-form>
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" v-if="currentStep == 1">
                                <a-form-item label="支付密码" required>
                                    <a-input v-model="pwd.password" type="password" placeholder="请输入支付密码" size="large"></a-input>
                                </a-form-item>
                                <a-form-item label="确认密码" required>
                                    <a-input v-model="pwd.confirm_password" type="password" placeholder="请再次输入支付密码" size="large"></a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-row>
                                        <a-col :span="16" offset="7">
                                            <a-button :disabled="pwd.password != pwd.confirm_password" @click="setPwd">下一步</a-button>
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-form>
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" v-if="currentStep == 2">
                                <a-result
                                        status="success"
                                        title="操作成功"
                                >
                                </a-result>
                            </a-form>

                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
    </a-spin>
</template>

<script>

import service from "../../../utils/request";
export default {
    name: "buyer-setting-payment-password",
    components: {
    },
    data(){
        return {
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            spinning: false,
            currentStep: 0,
            steps:[
                {title:'安全验证'},
                {title:'设置密码'},
                {title:'完成'},
            ],
            getVerifyCodeText:'获取验证码',
            countDown: 60,
            verify_code:'',
            pwd:{
                password:'',
                confirm_password: '',
            },
            timer: null
        }
    },
    methods:{
        getVerifyCode( type){
            if(this.countDown < 60) return false;
            service.post(service.uri.user.getVerifyCode, {phone: this.userInfo.mobile, type: type}).then(() => {

                this.timer = setInterval(() => {
                    this.countDown--;
                    if(this.countDown <= 0){
                        this.getVerifyCodeText = '获取验证码';
                        this.countDown = 60;
                        clearInterval(this.timer);
                        return ;
                    }
                    this.getVerifyCodeText = '请等待'+this.countDown+'S';
                }, 1000);
            });
        },
        verifyPhone(){
            service.post(service.uri.user.verifyPhone, {verify_code: this.verify_code, type: 11}).then(res => {
                if(res.code == 200){
                    clearInterval(this.timer);
                    this.countDown = 60;
                    this.getVerifyCodeText = '获取验证码';
                    this.currentStep = 1;
                    this.verify_code = '';
                }
            });
        },
        setPwd(){
            service.post(service.uri.user.setPayPassword, {password: this.pwd.password}).then(res => {
                if(res.code == 200){
                    clearInterval(this.timer);
                    this.currentStep = 2;
                }
            });
        },
    }

}
</script>

<style lang="less" scoped>
.container {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    min-height: 600px;
    .nav-wrap {
        width: 100%;
        background-color: #fff;
        .wrap {
            padding: 14px 0 12px;
            .head-title {
                margin-left: 1rem;
                color: #333;
                font-weight: 700;
                font-size: 20px;
                vertical-align: middle;
            }
        }
    }
    .container-content {
        flex: 1;
    }
}
.alain-pro__page-grid {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: .3s;
}

.page-grid {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;

}
.alain-pro__page-grid {
    background: #f2f3f5 url('../../assets/img/bg-password.png') no-repeat 50%/100%;
}
.getVerifyCode{
    cursor: pointer;
    color: @primary-color;
}
.getVerifyCode.disableColor{
    color: @disabled-color;
}
</style>